html,
body {
  height: 100%;
  width: 100%;
}

body {
  font-family: var(--main-font);
  background: #fff;
  color: #989dbb;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 20px;
  overflow: hidden;
  letter-spacing: 0.5px;
}
@media (min-width: 768px) {
  body {
    font-size: 14px;
    line-height: 23px;
  }
}
@media (min-width: 992px) {
  body {
    font-size: 16px;
    line-height: 25px;
  }
}

a,
.btn {
  transition: all 0.2s linear 0.1s;
}

ul {
  list-style: none;
}

hr {
  display: block;
  margin: 30px 0px;
}

textarea,
input,
input:hover,
input:focus,
input:active,
input[type=text],
input[type=text]:focus,
input[type=text]:hover,
input[type=text]:active,
input[type=email],
input[type=email]:focus,
input[type=email]:hover,
input[type=email]:active,
button,
button[type=submit],
.btn,
.btn:focus,
.btn:active {
  box-shadow: none;
  outline: 0px !important;
}

.no-overflow {
  overflow: hidden !important;
}

.css3animate {
  transition: all 0.2s linear 0.1s;
}

.css3animateSlow {
  transition: all 1s linear 0.5s;
}

.clearfix {
  margin: 20px 0px;
}

.hide {
  display: none;
}

.poster-image {
  background-repeat: no-repeat;
  background-size: cover;
  backface-visibility: hidden;
  background-position: center center;
  width: 100%;
  height: 100%;
  z-index: -1 !important;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: hidden;
}

.no-visible {
  display: none;
}

#big-video-wrap {
  position: absolute !important;
}

#video-content {
  position: fixed;
  z-index: -4 !important;
  min-width: 100%;
  min-height: 100%;
  left: 0px;
  top: 0px;
  overflow: hidden;
  opacity: 1;
  background-image: none;
  transition-property: opacity;
  transition-duration: 1500ms;
}

#vimeo_player_wrapper_vimeo_player_video {
  z-index: -4 !important;
  position: absolute !important;
}

#controlBar_bgndVideo .buttonBar {
  display: none;
}

.video-controls {
  position: absolute;
  z-index: 100;
  bottom: 15px;
  right: 20px;
}
.video-controls button {
  opacity: 0.75;
  border: 0px;
  transition: all 0.25s;
  background: none;
  text-align: center;
  padding: 0px;
  margin-right: 10px;
  font-size: 1em;
  line-height: 30px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  vertical-align: top;
  cursor: pointer;
}

.light-scheme {
  background: var(--light-back-main-color);
}
.light-scheme .video-controls button {
  border: 2px solid var(--light-head-color);
  color: var(--light-head-color);
}
.light-scheme .video-controls button:hover {
  background: var(--light-accent-color);
  border: 2px solid var(--light-accent-color);
  color: var(--light-back-main-color);
}
.light-scheme .background-secondary {
  background: var(--light-back-secondary-color);
}
.light-scheme .border-top, .light-scheme.border-top {
  border-top: 1px solid var(--light-back-secondary-color) !important;
}
.light-scheme .border-bottom, .light-scheme.border-bottom {
  border-bottom: 1px solid var(--light-back-secondary-color) !important;
}
.light-scheme .shadow-blanco {
  box-shadow: 0 1rem 2.5rem rgba(var(--light-head-color-rgb), 0.08);
}

.dark-scheme {
  background: var(--dark-back-main-color);
}
.dark-scheme .video-controls button {
  border: 2px solid var(--dark-head-color);
  color: var(--dark-head-color);
}
.dark-scheme .video-controls button:hover {
  background: var(--dark-accent-color);
  border: 2px solid var(--dark-accent-color);
  color: var(--dark-back-main-color);
}
.dark-scheme .background-secondary {
  background: var(--dark-back-secondary-color);
}
.dark-scheme .border-top, .dark-scheme.border-top {
  border-top: 1px solid var(--dark-back-secondary-color) !important;
}
.dark-scheme .border-bottom, .dark-scheme.border-bottom {
  border-bottom: 1px solid var(--dark-back-secondary-color) !important;
}
.dark-scheme .shadow-blanco {
  box-shadow: 0 1rem 2.5rem rgba(var(--dark-head-color-rgb), 0.08);
}

.back-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}

.pt-4b {
  padding-top: 4rem !important;
}

.pb-4b {
  padding-bottom: 4rem !important;
}

.py-4b {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.pt-5b {
  padding-top: 5rem !important;
}

.pb-5b {
  padding-bottom: 5rem !important;
}

.py-5b {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.pt-6 {
  padding-top: 6rem !important;
}

.pb-6 {
  padding-bottom: 6rem !important;
}

.py-6 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.pt-7 {
  padding-top: 7rem !important;
}

.pb-7 {
  padding-bottom: 7rem !important;
}

.py-7 {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;
}

@media (min-width: 768px) {
  .pt-8 {
    padding-top: 8rem !important;
  }

  .pb-8 {
    padding-bottom: 8rem !important;
  }

  .py-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .pt-9 {
    padding-top: 9rem !important;
  }

  .pb-9 {
    padding-bottom: 9rem !important;
  }

  .py-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .pt-10 {
    padding-top: 10rem !important;
  }

  .pb-10 {
    padding-bottom: 10rem !important;
  }

  .py-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .pt-11 {
    padding-top: 11rem !important;
  }

  .pb-11 {
    padding-bottom: 11rem !important;
  }

  .py-11 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }

  .pt-12 {
    padding-top: 12rem !important;
  }

  .pb-12 {
    padding-bottom: 12rem !important;
  }

  .py-12 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
}
h1,
h2,
h3,
h4,
h5,
.header-font {
  font-family: var(--heading-font);
  font-weight: 700;
}

h1 {
  font-size: 4em;
  letter-spacing: 0.025em;
}

h2 {
  font-size: 2.625em;
  letter-spacing: 0.025em;
}

h3 {
  font-size: 1.44em;
  letter-spacing: 0.025em;
}

h4 {
  font-size: 1.125em;
  line-height: 1.1em;
}
@media (min-width: 576px) {
  h4 {
    line-height: initial;
  }
}

.fnt-small {
  font-size: 0.875em;
}

h5 {
  font-size: 1.25em;
}
@media (min-width: 768px) {
  h5 {
    font-size: 0.875em;
  }
}

.light-scheme h1,
.light-scheme h2,
.light-scheme h3,
.light-scheme h4,
.light-scheme h5 {
  color: var(--light-head-color);
}
.light-scheme .accent-color, .light-scheme.accent-color {
  color: var(--light-accent-color);
}
.light-scheme .main-color, .light-scheme.main-color {
  color: var(--light-main-color);
}

.dark-scheme h1,
.dark-scheme h2,
.dark-scheme h3,
.dark-scheme h4,
.dark-scheme h5 {
  color: var(--dark-head-color);
}
.dark-scheme .accent-color, .dark-scheme.accent-color {
  color: var(--dark-accent-color);
}
.dark-scheme .main-color, .dark-scheme.main-color {
  color: var(--dark-main-color);
}

i.h1, i.h2, i.h3, i.h4, i.h5 {
  font-weight: 700;
}

.fnt-bold {
  font-weight: 700;
}

.btn,
a.btn {
  letter-spacing: 0.025em;
}

.light-scheme {
  color: var(--light-main-color);
}
.light-scheme .btn,
.light-scheme a.btn {
  border: 0px;
  color: var(--light-back-main-color);
  background: var(--light-accent-color);
}
.light-scheme a,
.light-scheme button {
  color: var(--light-head-color);
}
.light-scheme a:hover,
.light-scheme button:hover {
  color: var(--light-accent-color);
}
.light-scheme .btn:hover,
.light-scheme a.btn:hover {
  border: 0px;
  color: var(--light-back-main-color);
  background: var(--light-accent-hover-color);
}
.light-scheme .btn.btn-outline,
.light-scheme a.btn.btn-outline {
  border: 1px solid var(--light-head-color);
  color: var(--light-head-color);
  background: transparent;
}
.light-scheme .btn.btn-outline:hover,
.light-scheme a.btn.btn-outline:hover {
  border: 1px solid var(--light-accent-color);
  color: var(--light-back-main-color);
  background: var(--light-accent-color);
}

.dark-scheme {
  color: var(--dark-main-color);
}
.dark-scheme .btn,
.dark-scheme a.btn {
  border: 0px;
  color: var(--dark-back-main-color);
  background: var(--dark-accent-color);
}
.dark-scheme a,
.dark-scheme button {
  color: var(--dark-head-color);
}
.dark-scheme a:hover,
.dark-scheme button:hover {
  color: var(--dark-accent-color);
}
.dark-scheme .btn:hover,
.dark-scheme a.btn:hover {
  border: 0px;
  color: var(--dark-back-main-color);
  background: var(--dark-accent-hover-color);
}
.dark-scheme .btn.btn-outline,
.dark-scheme a.btn.btn-outline {
  border: 1px solid var(--dark-head-color);
  color: var(--dark-head-color);
  background: transparent;
}
.dark-scheme .btn.btn-outline:hover,
.dark-scheme a.btn.btn-outline:hover {
  border: 1px solid var(--dark-accent-color);
  color: var(--dark-back-main-color);
  background: var(--dark-accent-color);
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1100;
}
.loader #loader-content {
  border-radius: 50%;
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  margin-left: -80px;
  margin-top: -80px;
  width: 160px;
  height: 160px;
}
.loader #loader-content .loader-logo,
.loader #loader-content #loader-circle {
  position: absolute;
}
.loader #loader-content .loader-logo {
  top: 50%;
  left: 50%;
  font-size: 7em;
  line-height: 1em;
  transform: translate(-50%, -50%);
}
.loader #loader-content img.loader-image {
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90px;
}

.light-cheme .loader-logo {
  color: var(--light-accent-color);
}

.dark-cheme .loader-logo {
  color: var(--dark-accent-color);
}

#mainNav {
  position: fixed;
  z-index: 9;
  width: 100%;
  top: -100px;
  padding: 2rem 0rem;
}
@media (min-width: 768px) {
  #mainNav {
    padding: 3rem 0rem;
  }
}
#mainNav .navbar-brand img {
  max-height: 30px;
}
@media (min-width: 576px) {
  #mainNav .navbar-brand img {
    max-height: 45px;
  }
}
#mainNav h1 {
  line-height: 0px;
}
#mainNav ul li {
  text-transform: uppercase;
  letter-spacing: 0.3em;
  margin: 0rem 1.5rem;
}
#mainNav ul li button {
  display: inline-block;
  height: 50px;
  position: relative;
  background: none;
  border: 0px;
  text-transform: uppercase;
  font-size: 0.875em;
  font-weight: 700;
  letter-spacing: 0.035em;
}
#mainNav ul li button:hover, #mainNav ul li button:active {
  background: none;
}
#mainNav ul li button::before, #mainNav ul li button::after {
  position: absolute;
  width: 100%;
  height: 1px;
  top: 75%;
  left: 0;
  pointer-events: none;
  opacity: 0;
  transform-origin: 50% 0%;
  transform: translate3d(0, 3px, 0);
  transition-property: transform, opacity;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.8, 1);
}
#mainNav ul li button::before {
  content: "";
  transition-delay: 0.1s;
}
#mainNav ul li button::after {
  content: "";
  top: calc(75% + 4px);
  width: 70%;
  left: 15%;
}
#mainNav ul li button:hover::before, #mainNav ul li button:hover::after {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-timing-function: cubic-bezier(0.2, 0, 0.3, 1);
}
#mainNav ul li button:hover::after {
  transition-delay: 0.1s;
}
#mainNav ul li button:hover::before {
  transition-delay: 0s;
}
#mainNav ul li button:hover .line {
  width: 100%;
  left: 0%;
}
#mainNav.navTop ul li button {
  font-size: 0.8em;
}
#mainNav.dark-scheme {
  background: transparent;
}
#mainNav.dark-scheme > div {
  background: transparent;
}
#mainNav.dark-scheme .logo-header-light {
  display: none;
}
#mainNav.dark-scheme .logo-header-dark {
  display: block;
}
#mainNav.dark-scheme ul li button {
  color: var(--dark-head-color);
}
#mainNav.dark-scheme ul li button:hover {
  color: var(--dark-accent-hover-color);
}
#mainNav.dark-scheme ul li button::before, #mainNav.dark-scheme ul li button::after {
  background: var(--dark-accent-color);
}
#mainNav.dark-scheme ul li button:hover::before, #mainNav.dark-scheme ul li button:hover::after {
  background: var(--dark-accent-hover-color);
}
#mainNav.dark-scheme .burger span {
  background: var(--dark-accent-color);
}
#mainNav.dark-scheme .burger:hover span {
  background: var(--dark-accent-hover-color);
}
#mainNav.light-scheme {
  background: transparent;
}
#mainNav.light-scheme > div {
  background: transparent;
}
#mainNav.light-scheme .logo-header-dark {
  display: none;
}
#mainNav.light-scheme .logo-header-light {
  display: block;
}
#mainNav.light-scheme ul li button {
  color: var(--light-head-color);
}
#mainNav.light-scheme ul li button:hover {
  color: var(--light-accent-hover-color);
}
#mainNav.light-scheme ul li button::before, #mainNav.light-scheme ul li button::after {
  background: var(--light-accent-color);
}
#mainNav.light-scheme ul li button:hover::before, #mainNav.light-scheme ul li button:hover::after {
  background: var(--light-accent-hover-color);
}
#mainNav.light-scheme .burger span {
  background: var(--light-head-color);
}
#mainNav.light-scheme .burger:hover span {
  background: var(--light-accent-hover-color);
}
#mainNav.navTop {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}
@media (min-width: 576px) {
  #mainNav.navTop {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
#mainNav.navTop ul li button {
  font-size: 0.8em;
}
#mainNav.navTop.light-scheme {
  background: var(--light-back-main-color);
  border-bottom: 1px solid var(--light-back-secondary-color);
}
#mainNav.navTop.dark-scheme {
  background: var(--dark-back-main-color);
  border-bottom: 1px solid var(--dark-back-secondary-color);
}
#mainNav a {
  opacity: 0.75;
  cursor: pointer;
  margin-left: 1.2rem;
}
#mainNav a:hover {
  opacity: 1;
}
#mainNav .burger {
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
}
#mainNav .burger span {
  align-self: flex-end;
  height: 2px;
  width: 50%;
  transition: all 0.2s ease-in-out;
}
#mainNav .burger span:nth-child(2) {
  width: 75%;
}
#mainNav .burger span:nth-child(3) {
  width: 100%;
}
#mainNav .burger:hover span {
  width: 100%;
}
#mainNav .burger:hover span:nth-child(2) {
  width: 50%;
}
#mainNav .burger:hover span:nth-child(3) {
  width: 75%;
}
#mainNav .social-icons a:first-child {
  margin-left: 0rem;
}
#mainNav #menuMobileOpen {
  opacity: 1;
  cursor: pointer;
  margin-left: 0rem;
  text-decoration: none;
  font-size: 2em;
}
@media (min-width: 576px) {
  #mainNav #menuMobileOpen {
    font-size: 2.5em;
  }
}

.mobile-nav {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  text-align: center;
  background: #FFF;
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  transition: all 0.375s;
}
.mobile-nav::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateX(-120%);
  transition: all 0.275s 0.1s;
}
.mobile-nav.light-scheme {
  background: var(--light-head-color);
}
.mobile-nav.light-scheme::before {
  background: var(--light-back-main-color);
}
.mobile-nav.light-scheme .close-mobile-nav span {
  background: var(--light-head-color);
}
.mobile-nav.dark-scheme {
  background: var(--dark-head-color);
}
.mobile-nav.dark-scheme::before {
  background: var(--dark-back-main-color);
}
.mobile-nav.dark-scheme .close-mobile-nav span {
  background: var(--dark-head-color);
}
.mobile-nav.is-open {
  opacity: 1;
  z-index: 100;
  visibility: visible;
}
.mobile-nav.is-open::before {
  transform: translateX(0);
}
.mobile-nav.is-open button {
  opacity: 1;
  transform: translateY(0);
}
.mobile-nav ul {
  display: inline-flex;
  flex-direction: column;
  transform: translateX(-10%);
}
.mobile-nav ul li {
  display: block;
}
.mobile-nav ul li .close-mobile-nav {
  cursor: pointer;
  width: 40px;
  height: 40px;
  transition: all 0.2s ease-in-out;
  z-index: 100;
}
.mobile-nav ul li .close-mobile-nav span {
  width: 100%;
  height: 2px;
  transform: rotate(45deg);
}
.mobile-nav ul li .close-mobile-nav span:last-of-type {
  transform: rotate(-45deg);
  margin-top: -2px;
}
.mobile-nav ul li .close-mobile-nav:hover {
  transform: rotate(90deg);
}
.mobile-nav ul li.social-icons a {
  font-size: 1.5em;
  cursor: pointer;
}
.mobile-nav ul li button {
  font-size: 2.2em;
  font-family: var(--heading-font);
  font-weight: 700;
  opacity: 0;
  transform: translateY(-10px);
}
.mobile-nav ul li:nth-child(1) button {
  transition: all 275ms 175ms;
}
.mobile-nav ul li:nth-child(2) button {
  transition: all 275ms 225ms;
}
.mobile-nav ul li:nth-child(3) button {
  transition: all 275ms 275ms;
}
.mobile-nav ul li:nth-child(4) button {
  transition: all 275ms 325ms;
}
.mobile-nav ul li:nth-child(5) button {
  transition: all 275ms 375ms;
}
.mobile-nav ul li:nth-child(6) a {
  transition: all 275ms 425ms;
}

.pt-page {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  visibility: hidden;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  transform-style: preserve-3d;
  overflow: auto;
  opacity: 0;
}

.pt-page-current,
.no-js .pt-page {
  visibility: visible;
  opacity: 1;
  z-index: 1;
}

.pt-page a {
  padding: 8px 15px;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}
@media (min-width: 576px) {
  .pt-page a {
    padding: 10px 15px;
  }
}

footer {
  position: relative;
}
footer a {
  border: 0px;
}
footer a.toTop {
  font-size: 1.25em;
  z-index: 100;
  width: 45px;
  height: 45px;
  line-height: 45px;
  margin-top: 2rem;
  position: absolute;
  left: 50%;
  margin-left: -18px !important;
  bottom: 25px;
}
@media (min-width: 576px) {
  footer a.toTop {
    margin-top: 0rem;
    bottom: 50px;
    right: 20px;
    margin-left: 0px !important;
    left: inherit;
    width: 54px;
    height: 54px;
    line-height: 54px;
  }
}
footer.light-scheme a {
  color: var(--light-head-color);
}
footer.light-scheme a:hover {
  color: var(--light-accent-color);
}
footer.light-scheme a.toTop {
  border: 1px solid var(--light-head-color);
  color: var(--light-head-color);
}
footer.light-scheme a.toTop:hover {
  border: 1px solid var(--light-accent-color);
  color: var(--light-back-main-color);
  background: var(--light-accent-color);
}
footer.light-scheme .social-icons a {
  color: var(--light-main-color);
}
footer.light-scheme .social-icons a:hover {
  color: var(--light-accent-color);
}
footer.dark-scheme a {
  color: var(--dark-head-color);
}
footer.dark-scheme a:hover {
  color: var(--dark-accent-color);
}
footer.dark-scheme a.toTop {
  color: var(--dark-head-color);
  border: 1px solid var(--dark-head-color);
}
footer.dark-scheme a.toTop:hover {
  background: var(--dark-accent-color);
  color: var(--dark-back-main-color);
  border: 1px solid var(--dark-accent-color);
}
footer.dark-scheme .social-icons a {
  color: var(--dark-main-color);
}
footer.dark-scheme .social-icons a:hover {
  color: var(--dark-accent-color);
}
footer .copyright a {
  border: 0px;
  padding: 0px;
  text-decoration: none;
  font-weight: 700;
}
footer .copyright a:hover {
  background: none;
  border: 0px;
}

body.error {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
}
body.error h1 {
  font-size: 7.5em;
  margin: 15px 0px;
  font-weight: 700;
}
body.error h2 {
  font-weight: 700;
}

.cookies-message {
  position: fixed;
  z-index: 1001;
  bottom: 20px;
  left: 0px;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.cookies-message a {
  font-weight: 900;
  text-decoration: none;
}
.cookies-message ul {
  list-style-type: circle;
}
.cookies-message ul ul,
.cookies-message ol ul {
  list-style-type: disc;
}
.cookies-message.boxed-right {
  right: 0px;
  left: initial;
}
@media (min-width: 768px) {
  .cookies-message.boxed-left, .cookies-message.boxed-right {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .cookies-message.boxed-left, .cookies-message.boxed-right {
    width: 35%;
  }
}
.cookies-message.show {
  opacity: 1;
  visibility: visible;
  bottom: 0px;
}

.homepage .messages h2 {
  font-size: 3.5em;
  word-break: break-word;
}
@media (min-width: 576px) {
  .homepage .messages h2 {
    font-size: 4.35em;
  }
}
@media (min-width: 375px) {
  .homepage .messages h2 {
    word-break: initial;
  }
}
.homepage .messages h2 span.underlined {
  position: relative;
  white-space: pre;
}
.homepage .messages h2 span.underlined:after {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: 10%;
  left: 0;
  width: 100%;
  height: 25%;
  opacity: 0.3;
}
.homepage .messages > div {
  display: none;
}
.homepage.light-scheme .messages h2 {
  color: var(--light-head-color);
}
.homepage.light-scheme .messages h2 span.underlined:after {
  background: var(--light-head-color);
}
.homepage.dark-scheme .messages h2 {
  color: var(--dark-head-color);
}
.homepage.dark-scheme .messages h2 span.underlined:after {
  background: var(--dark-head-color);
}

.aboutme .aboutme-title h1 span.underlined {
  position: relative;
}
.aboutme .aboutme-title h1 span.underlined:after {
  content: "";
  position: absolute;
  bottom: 10%;
  left: 0;
  width: 100%;
  height: 25%;
  opacity: 0.25;
}
.aboutme .aboutme-title h4 {
  font-family: var(--main-font);
}
@media (min-width: 768px) {
  .aboutme .aboutme-title .aboutme-texts {
    margin-left: 5%;
    width: 35%;
  }
}
.aboutme .aboutme-title .aboutme-image {
  background-position: center 100px;
  min-height: 500px;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .aboutme .aboutme-title .aboutme-image {
    background-position: center -25%;
    min-height: initial;
  }
}
.aboutme.light-scheme .aboutme-title h1 span.underlined:after {
  background: var(--light-accent-color);
}
.aboutme.light-scheme .aboutme-info svg.waves-1 {
  fill: url(#light-waves-1) var(--light-main-color);
}
.aboutme.light-scheme .aboutme-info svg.waves-2 {
  fill: url(#light-waves-2) var(--light-main-color);
}
.aboutme.light-scheme .aboutme-info .aboutme-info-content:after {
  background: var(--light-main-color);
}
.aboutme.dark-scheme .aboutme-title h1 span.underlined:after {
  background: var(--dark-accent-color);
}
.aboutme.dark-scheme .aboutme-info svg.waves-1 {
  fill: url(#dark-waves-1) var(--dark-main-color);
}
.aboutme.dark-scheme .aboutme-info svg.waves-2 {
  fill: url(#dark-waves-2) var(--dark-main-color);
}
.aboutme.dark-scheme .aboutme-info .aboutme-info-content:after {
  background: var(--dark-main-color);
}
.aboutme .aboutme-info {
  font-size: 1.1em;
  letter-spacing: 0.025em;
}
.aboutme .aboutme-info .row {
  position: relative;
}
.aboutme .aboutme-info svg {
  position: absolute;
  width: auto;
  padding: 0px;
}
.aboutme .aboutme-info svg.waves-1 {
  top: -10%;
  left: 0%;
}
.aboutme .aboutme-info svg.waves-2 {
  bottom: 10%;
  right: 0%;
}
.aboutme .aboutme-info .aboutme-info-content {
  position: relative;
}
.aboutme .aboutme-info .aboutme-info-content p {
  line-height: 1.3em;
}
.aboutme .aboutme-info .aboutme-info-content:last-child:after {
  display: none;
}
.aboutme .aboutme-info .aboutme-info-content:after {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  display: none;
}
@media (min-width: 768px) {
  .aboutme .aboutme-info .aboutme-info-content:after {
    display: block;
  }
}

#light-waves-1 {
  --color-stop-1: rgba(var(--light-head-color-rgb), 1);
  --color-stop-2: rgba(var(--light-head-color-rgb), 0);
}

#light-waves-2 {
  --color-stop-1: rgba(var(--light-head-color-rgb), 0);
  --color-stop-2: rgba(var(--light-head-color-rgb), 1);
}

#dark-waves-1 {
  --color-stop-1: rgba(var(--dark-head-color-rgb), 1);
  --color-stop-2: rgba(var(--dark-head-color-rgb), 0);
}

#dark-waves-2 {
  --color-stop-1: rgba(var(--dark-head-color-rgb), 0);
  --color-stop-2: rgba(var(--dark-head-color-rgb), 1);
}

.skills span {
  font-size: 1.6em;
  vertical-align: -20%;
  margin-right: 15px;
}
.skills .bar-graph {
  position: relative;
  margin-bottom: 25px;
}
.skills .bar-graph .main-layer {
  height: 7px;
  position: relative;
  z-index: 1;
}
.skills .bar-graph .percent-layer {
  height: 7px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.skills .bar {
  position: relative;
  padding-right: 100px;
}
.skills .bar .bar-percentage {
  position: absolute;
  font-size: 3.125em;
  opacity: 0.25;
  font-family: var(--heading-font);
  font-weight: 700;
  right: 0px;
  top: 60%;
  transform: translateY(-50%);
  width: 100px;
}
.skills .bar:hover .bar-percentage {
  opacity: 1;
}
.skills .easyPieChart {
  position: relative;
  text-align: center;
}
.skills .easyPieChart canvas {
  position: absolute;
  top: 0;
  left: 0;
}
.skills .pie-chart {
  padding: 0px;
  float: left;
  position: relative;
  margin-right: 2rem;
}
.skills .pie-chart:last-child {
  margin-right: 0rem;
}
.skills .pie-chart .percentage,
.skills .pie-chart .percentage-title {
  width: 100%;
  text-align: center;
  position: absolute;
  line-height: initial;
}
.skills .pie-chart .percentage {
  top: 35%;
  font-family: var(--heading-font);
  font-weight: 700;
  transform: translateY(-50%);
}
@media (min-width: 768px) {
  .skills .pie-chart .percentage {
    top: 45%;
  }
}
.skills .pie-chart .percentage-title {
  bottom: 30%;
}
@media (min-width: 768px) {
  .skills .pie-chart .percentage-title {
    bottom: -20%;
  }
}
.skills .timeline .lines {
  width: 70px;
}
.skills .timeline .lines .dot {
  width: 20px;
  height: 20px;
  background: #d1d6e6;
}
.skills .timeline .lines .line {
  width: 8px;
  height: 100px;
}
.skills .timeline .cards {
  margin-left: 60px;
  position: relative;
}
.skills .timeline .cards:after {
  position: absolute;
  content: "";
  width: 7px;
  height: 100%;
  top: 0px;
  left: -42px;
  z-index: 100;
}
.skills .timeline .cards .card {
  border-radius: 20px;
}
.skills .timeline .cards .card .card-date {
  font-size: 1em;
}
@media (min-width: 768px) {
  .skills .timeline .cards .card .card-date {
    font-size: 0.875em;
  }
}
.skills .timeline .cards .card .card-text {
  line-height: initial;
}
.skills .timeline .cards .card:after {
  position: absolute;
  content: "";
  z-index: 101;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: 50%;
  left: -50px;
  transform: translateY(-50%);
}
.skills.light-scheme .bar-graph .main-layer {
  background-color: var(--light-back-secondary-color);
}
.skills.light-scheme .bar-graph .general_bg {
  background-color: var(--light-accent-color);
}
.skills.light-scheme .bar .bar-percentage {
  color: var(--light-accent-color);
}
.skills.light-scheme .bar:hover p {
  color: var(--light-accent-color);
}
.skills.light-scheme .pie-chart h2 {
  color: var(--light-head-color);
}
.skills.light-scheme .pie-chart:hover h2,
.skills.light-scheme .pie-chart:hover p {
  color: var(--light-accent-color);
}
.skills.light-scheme .timeline .lines .dot {
  background-color: var(--light-main-color);
}
.skills.light-scheme .timeline .lines .line {
  background-color: var(--light-back-secondary-color);
}
.skills.light-scheme .timeline .cards:after {
  background: var(--light-back-secondary-color);
}
.skills.light-scheme .timeline .cards .card.border {
  background: var(--light-back-main-color);
  border-color: var(--light-back-secondary-color) !important;
}
.skills.light-scheme .timeline .cards .card.shadow {
  box-shadow: 0 0rem 1rem rgba(var(--light-head-color-rgb), 0.1) !important;
}
.skills.light-scheme .timeline .cards .card:hover {
  border-color: var(--light-accent-color) !important;
  background: var(--light-accent-color) !important;
  color: var(--light-back-main-color) !important;
}
.skills.light-scheme .timeline .cards .card:hover h4 {
  color: var(--light-back-main-color);
}
.skills.light-scheme .timeline .cards .card:hover:after {
  background: var(--light-accent-color);
}
.skills.light-scheme .timeline .cards .card:after {
  background: var(--light-main-color);
}
.skills.dark-scheme .bar-graph .main-layer {
  background-color: var(--dark-back-secondary-color);
}
.skills.dark-scheme .bar-graph .general_bg {
  background-color: var(--dark-accent-color);
}
.skills.dark-scheme .bar:hover p {
  color: var(--dark-accent-color);
}
.skills.dark-scheme .bar .bar-percentage {
  color: var(--dark-accent-color);
}
.skills.dark-scheme .pie-chart h2 {
  color: var(--dark-head-color);
}
.skills.dark-scheme .pie-chart:hover h2,
.skills.dark-scheme .pie-chart:hover p {
  color: var(--dark-accent-color);
}
.skills.dark-scheme .timeline .lines .dot {
  background-color: var(--dark-main-color);
}
.skills.dark-scheme .timeline .lines .line {
  background-color: var(--dark-back-secondary-color);
}
.skills.dark-scheme .timeline .cards:after {
  background: var(--dark-back-secondary-color);
}
.skills.dark-scheme .timeline .cards .card.border {
  background: var(--dark-back-main-color);
  border-color: var(--dark-back-secondary-color) !important;
}
.skills.dark-scheme .timeline .cards .card.shadow {
  box-shadow: 0 0rem 1rem rgba(var(--dark-head-color-rgb), 0.1) !important;
}
.skills.dark-scheme .timeline .cards .card:hover {
  border-color: var(--dark-accent-color) !important;
  background: var(--dark-accent-color) !important;
  color: var(--dark-back-main-color) !important;
}
.skills.dark-scheme .timeline .cards .card:hover h4 {
  color: var(--dark-back-main-color);
}
.skills.dark-scheme .timeline .cards .card:hover:after {
  background: var(--dark-accent-color);
}
.skills.dark-scheme .timeline .cards .card:after {
  background: var(--dark-main-color);
}

.testimonials {
  position: relative;
}
.testimonials .testimonials-messages .testimonial-content {
  padding: 3rem;
  border-radius: 4rem;
  position: relative;
}
@media (min-width: 768px) {
  .testimonials .testimonials-messages .testimonial-content {
    padding: 4rem;
  }
}
.testimonials .testimonials-messages .testimonial-content .comment {
  font-size: 1.875em;
  line-height: 1.2;
}
@media (min-width: 576px) {
  .testimonials .testimonials-messages .testimonial-content .comment {
    line-height: 1.35em;
  }
}
.testimonials .testimonials-messages .testimonial-content > svg.comment-quotes {
  max-width: 50px;
}
.testimonials .testimonials-messages .testimonial-content.light-scheme {
  box-shadow: 0 0rem 1rem rgba(var(--light-head-color-rgb), 0.1) !important;
}
.testimonials .testimonials-messages .testimonial-content.light-scheme svg.comment-quotes {
  fill: var(--light-head-color);
}
.testimonials .testimonials-messages .testimonial-content.light-scheme .comment {
  color: var(--light-head-color);
}
.testimonials .testimonials-messages .testimonial-content.light-scheme .comments-arrows button svg {
  fill: var(--light-head-color);
}
.testimonials .testimonials-messages .testimonial-content.light-scheme .comments-arrows button:hover svg {
  fill: var(--light-accent-color);
}
.testimonials .testimonials-messages .testimonial-content.light-scheme svg.waves {
  fill: url(#light-waves-testimonial) var(--light-accent-color);
}
.testimonials .testimonials-messages .testimonial-content.dark-scheme {
  box-shadow: 0 0rem 1rem rgba(var(--dark-head-color-rgb), 0.1) !important;
}
.testimonials .testimonials-messages .testimonial-content.dark-scheme svg.comment-quotes {
  fill: var(--dark-head-color);
}
.testimonials .testimonials-messages .testimonial-content.dark-scheme .comment {
  color: var(--dark-head-color);
}
.testimonials .testimonials-messages .testimonial-content.dark-scheme .comments-arrows button svg {
  fill: var(--dark-head-color);
}
.testimonials .testimonials-messages .testimonial-content.dark-scheme .comments-arrows button:hover svg {
  fill: var(--dark-accent-color);
}
.testimonials .testimonials-messages .testimonial-content.dark-scheme svg.waves {
  fill: url(#dark-waves-testimonial) var(--dark-accent-color);
}
.testimonials .testimonials-messages .testimonial-content .comments-arrows button svg {
  width: 40px;
}
.testimonials .testimonials-messages .testimonial-content .comments-arrows button:hover svg {
  transform: scale(1.3);
}
.testimonials .testimonials-messages .testimonial-content svg.waves {
  position: absolute;
  bottom: 25px;
  right: 25px;
  width: 100px;
  height: 90px;
}
@media (min-width: 768px) {
  .testimonials .testimonials-messages .testimonial-content svg.waves {
    bottom: 50px;
    right: -50px;
    width: 150px;
  }
}
.testimonials .testimonials-messages .testimonial-image {
  height: 100%;
  margin-right: -30px;
  border-top-left-radius: 4rem;
  border-bottom-left-radius: 4rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center bottom;
  position: relative;
}
.testimonials .testimonials-messages .testimonial-image .testimonial-images-quotes {
  width: 150px;
  position: absolute;
  top: -50px;
  left: -50px;
  z-index: 100;
  transform: rotate(180deg);
}
.testimonials .testimonials-messages .testimonial-image.light-scheme {
  background-color: var(--light-back-secondary-color);
}
.testimonials .testimonials-messages .testimonial-image.light-scheme .testimonial-images-quotes {
  fill: var(--light-accent-color);
}
.testimonials .testimonials-messages .testimonial-image.dark-scheme {
  background-color: var(--dark-back-secondary-color);
}
.testimonials .testimonials-messages .testimonial-image.dark-scheme .testimonial-images-quotes {
  fill: var(--dark-accent-color);
}
.testimonials .testimonials-messages > div > div:last-child {
  z-index: 10;
}

#light-waves-testimonial {
  --color-stop-1: var(--light-accent-color);
  --color-stop-2: var(--light-back-main-color);
}

#dark-waves-testimonial {
  --color-stop-1: var(--dark-accent-color);
  --color-stop-2: var(--dark-back-main-color);
}

.services .carousel-nav button {
  width: 55px;
  height: 55px;
  line-height: 50px;
  background: transparent;
}
.services .carousel-nav button svg {
  width: 20px;
}
.services .services-content .item i {
  font-size: 3em;
}
.services .services-content .item .service-desc,
.services .services-content .item .service-info {
  font-size: 0.95em;
}
.services.light-scheme .carousel-nav button {
  border: 1px solid var(--light-head-color);
}
.services.light-scheme .carousel-nav button svg {
  fill: var(--light-head-color);
}
.services.light-scheme .carousel-nav button:hover {
  background: var(--light-accent-color);
  border: 1px solid var(--light-accent-color);
}
.services.light-scheme .carousel-nav button:hover svg {
  fill: var(--light-back-main-color);
}
.services.light-scheme .services-content .item {
  background: var(--light-back-main-color);
  border: 1px solid rgba(var(--light-main-color-rgb), 0.25);
}
.services.light-scheme .services-content .item .service-info {
  color: var(--light-head-color);
}
.services.light-scheme .services-content .item:hover {
  background: var(--light-accent-color);
  border: 1px solid var(--light-accent-color);
}
.services.light-scheme .services-content .item:hover i,
.services.light-scheme .services-content .item:hover h3,
.services.light-scheme .services-content .item:hover p,
.services.light-scheme .services-content .item:hover ul li {
  color: var(--light-back-main-color);
}
.services.dark-scheme .carousel-nav button {
  border: 1px solid var(--dark-head-color);
}
.services.dark-scheme .carousel-nav button svg {
  fill: var(--dark-head-color);
}
.services.dark-scheme .carousel-nav button:hover {
  background: var(--dark-accent-color);
  border: 1px solid var(--dark-accent-color);
}
.services.dark-scheme .carousel-nav button:hover svg {
  fill: var(--dark-back-main-color);
}
.services.dark-scheme .services-content .item {
  background: var(--dark-back-main-color);
  border: 1px solid rgba(var(--dark-main-color-rgb), 0.25);
}
.services.dark-scheme .services-content .item .service-info {
  color: var(--dark-head-color);
}
.services.dark-scheme .services-content .item:hover {
  background: var(--dark-accent-color);
  border: 1px solid var(--dark-accent-color);
}
.services.dark-scheme .services-content .item:hover i,
.services.dark-scheme .services-content .item:hover h3,
.services.dark-scheme .services-content .item:hover p,
.services.dark-scheme .services-content .item:hover ul li {
  color: var(--dark-back-main-color);
}

#filters button {
  cursor: pointer;
  font-size: 0.9em;
}

.dark-scheme #filters button {
  border: 1px solid rgba(var(--dark-main-color-rgb), 0.5);
  background: transparent;
  color: var(--dark-head-color);
}
.dark-scheme #filters button:hover, .dark-scheme #filters button.checked {
  color: var(--dark-back-main-color);
  background: var(--dark-accent-color);
  border: 1px solid var(--dark-accent-color);
}

.light-scheme #filters button {
  border: 1px solid rgba(var(--light-main-color-rgb), 0.5);
  background: transparent;
  color: var(--light-head-color);
}
.light-scheme #filters button:hover, .light-scheme #filters button.checked {
  color: var(--light-back-main-color);
  background: var(--light-accent-color);
  border: 1px solid var(--light-accent-color);
}

#gallery .grid-sizer {
  margin: 0px;
  float: left;
  border-radius: 0px;
  clear: none;
  overflow: hidden;
  position: absolute;
  width: 100%;
}
@media (min-width: 768px) {
  #gallery .grid-sizer {
    width: 50%;
  }
}
@media (min-width: 992px) {
  #gallery .grid-sizer {
    width: 33.33%;
  }
}
#gallery:after {
  content: "";
  display: block;
  clear: both;
}

.projects-content .item {
  margin: 0px;
  float: left;
  border-radius: 0px;
  clear: none;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 5px 5px 0px 0px;
  height: 350px;
}
@media (min-width: 768px) {
  .projects-content .item {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .projects-content .item {
    width: 33.33%;
  }
}
.projects-content .item.w2 {
  width: 100%;
}
@media (min-width: 992px) {
  .projects-content .item.w2 {
    width: 66.66%;
  }
}
.projects-content .item.w3 {
  width: 100%;
}
.projects-content .item a {
  border-radius: 0px;
  border: 0px;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  transition: all 0.8s;
}
@media (min-width: 992px) {
  .projects-content .item a {
    filter: grayscale(100%);
  }
}
.projects-content .item a:after {
  position: absolute;
  z-index: 100;
  content: "";
  opacity: 0.35;
  background: black;
  width: 100%;
  height: 100%;
  left: 0px;
  bottom: 0px;
  transition: all 0.4s;
}
@media (min-width: 992px) {
  .projects-content .item a:after {
    height: 0%;
    opacity: 0.85;
    background: black;
    background: linear-gradient(0deg, black 0%, rgba(0, 0, 0, 0) 100%);
  }
}
.projects-content .item a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.8s;
}
.projects-content .item a .project-text.style-1 {
  position: absolute;
  z-index: 101;
  width: 100%;
  left: 0px;
  bottom: 0px;
}
.projects-content .item a .project-text.style-2, .projects-content .item a .project-text.style-3 {
  text-align: center;
  position: absolute;
  z-index: 101;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.projects-content .item a .project-text.style-2 h3, .projects-content .item a .project-text.style-3 h3 {
  text-transform: uppercase;
}
.projects-content .item a .project-text h3 {
  line-height: 1.4em;
  transition: all 0.4s;
}
@media (min-width: 992px) {
  .projects-content .item a .project-text h3 {
    opacity: 0;
    visibility: hidden;
    transform: translateY(100px);
  }
}
.projects-content .item a .project-text p {
  font-size: 0.95em;
  transition: all 0.8s;
}
@media (min-width: 992px) {
  .projects-content .item a .project-text p {
    opacity: 0;
    visibility: hidden;
    transform: translateY(100px);
  }
}
@media (min-width: 992px) {
  .projects-content .item a:hover {
    filter: none;
  }
  .projects-content .item a:hover:after {
    height: 100%;
  }
  .projects-content .item a:hover img {
    transform: scale(110%);
  }
  .projects-content .item a:hover .project-text h3,
.projects-content .item a:hover .project-text p {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
  }
}

.project-title-container {
  position: relative;
}
.project-title-container > div {
  position: relative;
}
.project-title-container button {
  border: 1px solid #333;
  color: #333;
  border-radius: 50%;
  background: none;
  font-size: 1.5em;
  z-index: 100;
  width: 40px;
  height: 40px;
}
@media (min-width: 768px) {
  .project-title-container button {
    width: 50px;
    height: 50px;
  }
}
.project-title-container button span {
  width: 50%;
  height: 2px;
  transform: rotate(45deg);
  margin-left: 25%;
}
.project-title-container button span:last-of-type {
  transform: rotate(-45deg);
  margin-top: -2px;
}
.project-title-container button:hover {
  transform: rotate(90deg);
}

.project-media .project-video {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  margin-bottom: 1.25rem;
}
@media (min-width: 992px) {
  .project-media .project-video {
    margin-bottom: 0rem;
  }
}
.project-media .project-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.project-media .image-main {
  margin-bottom: 0.5rem;
}
@media (min-width: 992px) {
  .project-media .image-main {
    margin-bottom: 0rem;
  }
}
.project-media .image-main img {
  width: 100%;
  height: 230px;
  object-fit: cover;
}
@media (min-width: 768px) {
  .project-media .image-main img {
    height: 400px;
  }
}
@media (min-width: 992px) {
  .project-media .image-main img {
    height: 600px;
  }
}
.project-media .image-more {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}
@media (min-width: 768px) {
  .project-media .image-more {
    flex-direction: row;
  }
}
@media (min-width: 992px) {
  .project-media .image-more {
    flex-direction: column;
    gap: 1.25rem;
  }
}
.project-media .image-more img {
  width: 100%;
  height: 230px;
  object-fit: cover;
}
@media (min-width: 992px) {
  .project-media .image-more img {
    height: 290px;
  }
}
.project-media .image-more.images-more-video img {
  height: 230px;
}
@media (min-width: 992px) {
  .project-media .image-more.images-more-video img {
    height: 163px;
  }
}
@media (min-width: 1200px) {
  .project-media .image-more.images-more-video img {
    height: 197px;
  }
}
@media (min-width: 1400px) {
  .project-media .image-more.images-more-video img {
    height: 231px;
  }
}

.project-media a,
.project-gallery a {
  position: relative;
  padding: 0px;
}
.project-media a:after,
.project-gallery a:after {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: #000;
  z-index: 100;
  opacity: 0%;
  visibility: hidden;
  transition: all 0.3s;
}
.project-media a i,
.project-gallery a i {
  position: absolute;
  z-index: 101;
  top: 50%;
  left: 50%;
  font-size: 2em;
  margin-left: -24px;
  margin-top: -24px;
  opacity: 0%;
  visibility: hidden;
  transition: all 0.6s;
  transform: translateY(100px);
}
.project-media a:hover:after,
.project-gallery a:hover:after {
  opacity: 40%;
  visibility: visible;
}
.project-media a:hover i,
.project-gallery a:hover i {
  opacity: 100%;
  visibility: visible;
  transform: translateY(0px);
}

.project-gallery a img {
  width: 100%;
  height: 230px;
  object-fit: cover;
}
@media (min-width: 768px) {
  .project-gallery a {
    margin-bottom: 0.5rem;
  }
}

.content-text p,
.content-text ul,
.content-text blockquote,
.content-text .blockquote {
  margin-bottom: 1.5rem;
}
.content-text h1,
.content-text h1,
.content-text h3,
.content-text h4 {
  margin-bottom: 1.5rem;
  line-height: 1.3em;
}
@media (min-width: 992px) {
  .content-text p,
.content-text ul,
.content-text blockquote,
.content-text .blockquote,
.content-text h1,
.content-text h1,
.content-text h3,
.content-text h4 {
    margin-bottom: 2.25rem;
  }
}
.content-text ul {
  padding-left: 0px;
}
.content-text ul li {
  padding-left: 50px;
  margin-bottom: 0.5rem;
  position: relative;
}
.content-text ul li:last-child {
  margin-bottom: 0rem;
}
.content-text ul li:after {
  content: "";
  mask: url('data:image/svg+xml;charset=utf-8,<svg class="css3animate" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="92px" height="41.204px" viewBox="0 0 92 41.204" enable-background="new 0 0 92 41.204" xml:space="preserve"><path d="M3.12,22.757H83.8L70.166,36.619c-0.818,0.833-0.808,2.178,0.025,2.997c0.806,0.794,2.202,0.782,2.998-0.024l17.018-17.309 C90.711,21.876,91,21.276,91,20.637c0-0.034-0.003-0.074-0.008-0.107c-0.01-0.192-0.047-0.385-0.095-0.52 c-0.008-0.048-0.036-0.151-0.054-0.195c-0.045-0.114-0.101-0.182-0.091-0.158c-0.063-0.12-0.135-0.232-0.19-0.298 c-0.031-0.058-0.124-0.19-0.17-0.237L73.189,1.632C72.788,1.225,72.252,1,71.679,1c-0.56,0-1.088,0.217-1.487,0.608 c-0.832,0.819-0.844,2.165-0.024,2.999l13.683,13.91H3.12c-1.169,0-2.12,0.951-2.12,2.12C1,21.806,1.951,22.757,3.12,22.757z"/> </svg>') no-repeat 50% 50%;
  mask-size: cover;
  height: 8px;
  width: 25px;
  position: absolute;
  top: 8px;
  left: 0px;
}
.content-text ul.columns-2 {
  column-count: 2;
}
.content-text blockquote,
.content-text .blockquote {
  font-size: 1.125em;
  font-family: var(--heading-font);
  font-weight: 700;
  font-style: italic;
  padding-left: 70px;
  position: relative;
}
.content-text blockquote:after,
.content-text .blockquote:after {
  content: "";
  mask: url('data:image/svg+xml;charset=utf-8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 82.223" enable-background="new 0 0 100 82.223" xml:space="preserve"><g><path d="M25.79,79.723c-4.496,0-8.172-0.918-11.031-2.756c-2.863-1.84-5.21-4.186-7.049-7.047 c-2.25-3.268-3.677-6.844-4.289-10.727c-0.613-3.879-0.92-7.051-0.92-9.5c0-10.009,2.551-19.102,7.662-27.274 C15.266,14.25,23.233,7.607,34.064,2.5l2.757,5.516c-6.334,2.657-11.798,6.847-16.395,12.563 c-4.597,5.722-6.895,11.545-6.895,17.469c0,2.45,0.306,4.597,0.92,6.433c3.264-2.65,7.047-3.982,11.337-3.982 c5.311,0,9.907,1.739,13.79,5.209c3.879,3.479,5.823,8.275,5.823,14.404c0,5.723-1.944,10.418-5.823,14.096 C35.696,77.885,31.1,79.723,25.79,79.723z M77.886,79.723c-4.495,0-8.173-0.918-11.03-2.756c-2.865-1.84-5.212-4.186-7.051-7.047 c-2.25-3.268-3.675-6.844-4.29-10.727c-0.611-3.879-0.917-7.051-0.917-9.5c0-10.009,2.55-19.102,7.66-27.274 C67.362,14.25,75.33,7.607,86.16,2.5l2.757,5.516c-6.333,2.657-11.797,6.847-16.394,12.563c-4.597,5.722-6.895,11.545-6.895,17.469 c0,2.45,0.305,4.597,0.919,6.433c3.264-2.65,7.047-3.982,11.338-3.982c5.309,0,9.906,1.739,13.79,5.209 c3.879,3.479,5.824,8.275,5.824,14.404c0,5.723-1.945,10.418-5.824,14.096C87.792,77.885,83.194,79.723,77.886,79.723z"/></g></svg>') no-repeat 50% 50%;
  mask-size: cover;
  height: 33px;
  width: 40px;
  top: 0px;
  left: 0px;
  position: absolute;
  background-color: black;
}
.content-text img {
  margin: 0 0 2rem 0;
  width: 100%;
  height: 200px;
  object-fit: cover;
}
@media (min-width: 768px) {
  .content-text img {
    width: initial;
    height: auto;
    object-fit: inherit;
  }
  .content-text img.note-float-right {
    margin: 0.5rem 0rem 1rem 2rem;
  }
  .content-text img.note-float-left {
    margin: 0.5rem 2rem 1rem 0rem;
  }
}

.light-scheme .project-title-container button {
  border: 1px solid var(--light-main-color);
}
.light-scheme .project-title-container button span {
  background: var(--light-main-color);
}
.light-scheme .project-title-container button:hover {
  background: var(--light-accent-color);
  border: 1px solid var(--light-accent-color);
}
.light-scheme .project-title-container button:hover span {
  background: var(--light-back-main-color);
}
.light-scheme .project-media a:after,
.light-scheme .project-gallery a:after {
  background: var(--light-accent-color);
}
.light-scheme .project-media a i,
.light-scheme .project-gallery a i {
  color: var(--light-back-main-color);
}
.light-scheme .content-text blockquote,
.light-scheme .content-text .blockquote {
  color: var(--light-accent-color);
}
.light-scheme .content-text blockquote:after,
.light-scheme .content-text .blockquote:after {
  background-color: var(--light-accent-color);
}
.light-scheme .content-text ul li:after {
  background-color: var(--light-accent-color);
}
.light-scheme .project-information .project-information-list {
  background: var(--light-back-secondary-color);
}

.dark-scheme .project-title-container button {
  border: 1px solid var(--dark-main-color);
}
.dark-scheme .project-title-container button span {
  background: var(--dark-main-color);
}
.dark-scheme .project-title-container button:hover {
  background: var(--dark-accent-color);
  border: 1px solid var(--dark-accent-color);
}
.dark-scheme .project-title-container button:hover span {
  background: var(--dark-back-main-color);
}
.dark-scheme .project-media a:after,
.dark-scheme .project-gallery a:after {
  background: var(--dark-accent-color);
}
.dark-scheme .project-media a i,
.dark-scheme .project-gallery a i {
  color: var(--dark-back-main-color);
}
.dark-scheme .content-text blockquote,
.dark-scheme .content-text .blockquote {
  color: var(--dark-accent-color);
}
.dark-scheme .content-text blockquote:after,
.dark-scheme .content-text .blockquote:after {
  background-color: var(--dark-accent-color);
}
.dark-scheme .content-text ul li:after {
  background-color: var(--dark-accent-color);
}
.dark-scheme .project-information .project-information-list {
  background: var(--dark-back-secondary-color);
}

@media (min-width: 768px) {
  .blog .blog-content {
    margin-bottom: 0rem;
  }
}
.blog .blog-content .main-post-image .post-content-container .post-image img,
.blog .blog-content .main-post-image .post-content-container .post-gallery .carousel img,
.blog .blog-content .main-post-image .post-content-container .post-video img,
.blog .blog-content .main-post-image .post-content-container .post-quote img {
  height: 400px;
}
@media (min-width: 992px) {
  .blog .blog-content .main-post-image .post-content-container .post-image img,
.blog .blog-content .main-post-image .post-content-container .post-gallery .carousel img,
.blog .blog-content .main-post-image .post-content-container .post-video img,
.blog .blog-content .main-post-image .post-content-container .post-quote img {
    height: 635px;
  }
}
@media (min-width: 1200px) {
  .blog .blog-content .main-post-image .post-content-container .post-image img,
.blog .blog-content .main-post-image .post-content-container .post-gallery .carousel img,
.blog .blog-content .main-post-image .post-content-container .post-video img,
.blog .blog-content .main-post-image .post-content-container .post-quote img {
    height: 560px;
  }
}
.blog .blog-content .post-content-container {
  position: relative;
}
.blog .blog-content .post-content-container .post-image {
  overflow: hidden;
}
.blog .blog-content .post-content-container .post-image img {
  height: 200px;
  object-fit: cover;
}
@media (min-width: 768px) {
  .blog .blog-content .post-content-container .post-image img {
    height: 260px;
  }
}
.blog .blog-content .post-content-container .post-video {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  height: 200px;
}
@media (min-width: 768px) {
  .blog .blog-content .post-content-container .post-video {
    height: 260px;
  }
}
.blog .blog-content .post-content-container .post-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.blog .blog-content .post-content-container .post-gallery .carousel {
  overflow: hidden;
}
.blog .blog-content .post-content-container .post-gallery .carousel img {
  height: 200px;
  object-fit: cover;
}
@media (min-width: 768px) {
  .blog .blog-content .post-content-container .post-gallery .carousel img {
    height: 260px;
  }
}
.blog .blog-content .post-content-container .post-quote {
  height: 260px;
}
.blog .blog-content .post-content-container .post-content {
  padding: 2rem;
}
.blog .blog-content .post-content-container .main-post {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 100;
  background: rgba(var(--light-head-color-rgb), 0.5);
  padding: 2.5rem;
}
.blog .blog-content .post-content-container .main-post:hover {
  background: rgba(var(--light-head-color-rgb), 0.75);
}
.blog .blog-content .post-content-container .main-post p,
.blog .blog-content .post-content-container .main-post h4 {
  color: var(--light-back-main-color);
}
.blog .blog-content .post-content-container .main-post .info-post {
  font-size: 1.125em;
  margin-bottom: 1rem !important;
}
.blog .blog-content .post-content-container .main-post h4 {
  font-size: 2.62em;
  line-height: 1.2em;
}
@media (min-width: 768px) {
  .blog .blog-content .post-content-container .main-post h4 {
    margin-bottom: 2rem !important;
  }
}
.blog .blog-content .post-content-container .main-post .info-description {
  font-size: 1.25em;
  line-height: 1.5em;
  display: none;
}
@media (min-width: 768px) {
  .blog .blog-content .post-content-container .main-post .info-description {
    display: block;
  }
}
@media (min-width: 768px) {
  .blog .blog-content .post-content-container .main-post {
    padding: 4.5rem;
  }
  .blog .blog-content .post-content-container .main-post.main-post-wide {
    padding: 9rem;
    justify-content: center !important;
    text-align: center;
  }
}
.blog.light-scheme .blog-content .post-content {
  background: var(--light-back-main-color);
}
.blog.light-scheme .blog-content .post-content .info-post {
  color: var(--light-accent-color);
}
.blog.light-scheme .blog-content .post-quote {
  color: var(--light-back-main-color);
  background: url("../../../../assets/fronted/img/quote.png") var(--light-accent-color) top left no-repeat;
}
.blog.light-scheme .blog-content .post-quote h3 {
  font-size: 1.4em;
  color: var(--light-back-main-color);
}
.blog.blog.dark-scheme .blog-content .post-content {
  background: var(--dark-back-main-color);
}
.blog.blog.dark-scheme .blog-content .post-content .info-post {
  color: var(--dark-accent-color);
}
.blog.blog.dark-scheme .blog-content .post-quote {
  color: var(--dark-back-main-color);
  background: url("../../../../assets/fronted/img/quote_dark.png") var(--dark-accent-color) top left no-repeat;
}
.blog.blog.dark-scheme .blog-content .post-quote h3 {
  font-size: 1.4em;
  color: var(--dark-back-main-color);
}

.light-scheme .post-container .post-quote {
  color: var(--light-back-main-color);
  background: url("../../../../assets/fronted/img/quote.png") var(--light-accent-color) top left no-repeat;
}
.light-scheme .post-container .post-quote h3 {
  font-size: 1.4em;
  color: var(--light-back-main-color);
}
.light-scheme .post-container .post-text .blockquote,
.light-scheme .post-container .post-text blockquote {
  border-left: 4px solid var(--light-accent-color);
}

.dark-scheme .post-container .post-quote {
  color: var(--dark-back-main-color);
  background: url("../../../../assets/fronted/img/quote_dark.png") var(--dark-accent-color) top left no-repeat;
}
.dark-scheme .post-container .post-quote h3 {
  font-size: 1.4em;
  color: var(--dark-back-main-color);
}
.dark-scheme .post-container .post-text .blockquote,
.dark-scheme .post-container .post-text blockquote {
  border-left: 4px solid var(--dark-accent-color);
}

.blog-post .post-hide {
  border-radius: 25px;
  background: none;
  font-size: 1.5em;
  z-index: 100;
  font-size: 1em;
  text-decoration: none;
}
.blog-post .post-hide svg {
  width: 15px;
}
@media (min-width: 768px) {
  .blog-post .post-hide svg {
    width: 20px;
  }
}
.blog-post .post-info {
  font-size: 1.2em;
}
.blog-post .post-image {
  background-position: center center;
  clip-path: polygon(100% 0, 100% 90%, 50% 100%, 0 90%, 0 0);
}
.blog-post .post-image .post-title-container {
  background: rgba(0, 0, 0, 0.35);
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}
@media (min-width: 992px) {
  .blog-post .post-image .post-title-container {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
}
.blog-post .post-media img {
  height: 250px;
  width: 100%;
  object-fit: cover;
}
@media (min-width: 768px) {
  .blog-post .post-media img {
    height: 350px;
  }
}
@media (min-width: 992px) {
  .blog-post .post-media img {
    height: 450px;
  }
}
.blog-post .post-media .post-video {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  height: 200px;
}
@media (min-width: 768px) {
  .blog-post .post-media .post-video {
    height: 260px;
  }
}
.blog-post .post-media .post-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  .blog-post .container-media {
    padding-top: 6rem !important;
  }
}

.light-scheme .post-image h2,
.light-scheme .post-image p {
  color: var(--light-back-main-color);
}
.light-scheme .post-image .post-hide {
  border: 0px;
  background: var(--light-back-main-color);
  color: var(--light-head-color);
}
.light-scheme .post-image .post-hide svg {
  fill: var(--light-head-color);
}
.light-scheme .post-image .post-hide:hover {
  border: 0px;
  background: var(--light-accent-color);
  color: var(--light-back-main-color);
}
.light-scheme .post-image .post-hide:hover svg {
  fill: var(--light-back-main-color);
}
.light-scheme .post-hide {
  border: 1px solid var(--light-main-color);
  color: var(--light-main-color);
}
.light-scheme .post-hide svg {
  fill: var(--light-main-color);
}
.light-scheme .post-hide:hover {
  background: var(--light-accent-color);
  border: 1px solid var(--light-accent-color);
  color: var(--light-back-main-color);
}
.light-scheme .post-hide:hover svg {
  fill: var(--light-back-main-color);
}
.light-scheme .post-quote {
  color: var(--light-back-main-color);
  background: url("../../../../assets/fronted/img/quote.png") var(--light-accent-color) top left no-repeat;
}
.light-scheme .post-quote h3 {
  font-size: 1.4em;
  color: var(--light-back-main-color);
}

.dark-scheme .post-image h2,
.dark-scheme .post-image p {
  color: var(--dark-back-main-color);
}
.dark-scheme .post-image .post-hide {
  border: 0px;
  background: var(--dark-back-main-color);
  color: var(--dark-head-color);
}
.dark-scheme .post-image .post-hide svg {
  fill: var(--dark-head-color);
}
.dark-scheme .post-image .post-hide:hover {
  border: 0px;
  background: var(--dark-accent-color);
  color: var(--dark-back-main-color);
}
.dark-scheme .post-image .post-hide:hover svg {
  fill: var(--dark-back-main-color);
}
.dark-scheme .post-hide {
  border: 1px solid var(--dark-main-color);
  color: var(--dark-main-color);
}
.dark-scheme .post-hide svg {
  fill: var(--dark-main-color);
}
.dark-scheme .post-hide:hover {
  background: var(--dark-accent-color);
  border: 1px solid var(--dark-accent-color);
  color: var(--dark-back-main-color);
}
.dark-scheme .post-hide:hover svg {
  fill: var(--dark-back-main-color);
}
.dark-scheme .post-quote {
  color: var(--dark-back-main-color);
  background: url("../../../../assets/fronted/img/quote_dark.png") var(--dark-accent-color) top left no-repeat;
}
.dark-scheme .post-quote h3 {
  font-size: 1.4em;
  color: var(--dark-back-main-color);
}

@media (min-width: 768px) {
  form#contactform {
    margin-top: -1rem;
  }
}
input.form-control,
textarea.form-control {
  background: none !important;
  box-shadow: none;
  border: 0px;
  border-radius: 0px;
}
input.form-control:focus, input.form-control:active,
textarea.form-control:focus,
textarea.form-control:active {
  outline: none;
  border: none;
  box-shadow: none !important;
}

.light-scheme input.form-control,
.light-scheme textarea.form-control {
  border-bottom: 1px solid var(--light-back-secondary-color);
  color: var(--light-back-secondary-color);
}
.light-scheme input.form-control:focus, .light-scheme input.form-control:active,
.light-scheme textarea.form-control:focus,
.light-scheme textarea.form-control:active {
  border-bottom: 1px solid var(--light-accent-color);
  color: var(--light-accent-color);
}
.light-scheme input.form-control:-moz-placeholder, .light-scheme input.form-control:-ms-input-placeholder, .light-scheme input.form-control:-ms-input-placeholder, .light-scheme input.form-control:-webkit-input-placeholder,
.light-scheme textarea.form-control:-moz-placeholder,
.light-scheme textarea.form-control:-ms-input-placeholder,
.light-scheme textarea.form-control:-ms-input-placeholder,
.light-scheme textarea.form-control:-webkit-input-placeholder {
  color: var(--light-head-color);
  opacity: 0.7;
}

.dark-scheme input.form-control,
.dark-scheme textarea.form-control {
  border-bottom: 1px solid var(--dark-back-secondary-color);
  color: var(--dark-back-secondary-color);
}
.dark-scheme input.form-control:focus, .dark-scheme input.form-control:active,
.dark-scheme textarea.form-control:focus,
.dark-scheme textarea.form-control:active {
  border-bottom: 1px solid var(--dark-accent-color);
  color: var(--dark-accent-color);
}
.dark-scheme input.form-control:-moz-placeholder, .dark-scheme input.form-control:-ms-input-placeholder, .dark-scheme input.form-control:-ms-input-placeholder, .dark-scheme input.form-control:-webkit-input-placeholder,
.dark-scheme textarea.form-control:-moz-placeholder,
.dark-scheme textarea.form-control:-ms-input-placeholder,
.dark-scheme textarea.form-control:-ms-input-placeholder,
.dark-scheme textarea.form-control:-webkit-input-placeholder {
  color: var(--dark-head-color);
  opacity: 0.7;
}

.success-message,
.error-message {
  height: 30px;
}

.button-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 50px;
}
.button-loader.hidden {
  display: none;
}
.button-loader .lds-ring {
  width: 40px;
  height: 40px;
}
.button-loader .lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 4px;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.button-loader .lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.button-loader .lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.button-loader .lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#map {
  height: 400px;
}
@media (min-width: 768px) {
  #map {
    height: 450px;
  }
}
@media (min-width: 992px) {
  #map {
    height: 500px;
  }
}
#map #cd-zoom-in,
#map #cd-zoom-out {
  height: 32px;
  width: 32px;
  cursor: pointer;
  margin-left: 30px;
  opacity: 0.7;
  font-size: 1.3em;
  background-color: #333;
}
#map #cd-zoom-in span,
#map #cd-zoom-out span {
  width: 100%;
  float: left;
  margin-top: 7px;
}
#map #cd-zoom-in:hover,
#map #cd-zoom-out:hover {
  opacity: 1;
}
#map #cd-zoom-in {
  background-position: 50% 0;
  margin-top: 400px;
  margin-bottom: 1px;
}
#map #cd-zoom-out {
  background-position: 50% -32px;
}
#map .no-touch #cd-zoom-in:hover,
#map .no-touch #cd-zoom-out:hover {
  opacity: 1;
}