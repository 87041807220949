@use '../base/variables' as v;
@use '../base/mixins' as m;
.blog {
    .blog-content {
        @include m.tablet {
            margin-bottom: 0rem;
        }
        .main-post-image {
            .post-content-container {
                .post-image,
                .post-gallery .carousel,
                .post-video,
                .post-quote {
                    img {
                        height: 400px;
                        @include m.desktop {
                            height: 635px;
                        }
                        @include m.xdesktop {
                            height: 560px;
                        }
                    }
                }
            }
        }
        .post-content-container {
            position: relative;
            .post-image {
                overflow: hidden;
                img {
                    height: 200px;
                    object-fit: cover;
                    @include m.tablet {
                        height: 260px;
                    }
                }
            }
            .post-video {
                position: relative;
                overflow: hidden;
                width: 100%;
                padding-top: 56.25%;
                height: 200px;
                @include m.tablet {
                    height: 260px;
                }
                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                }
            }
            .post-gallery {
                .carousel {
                    overflow: hidden;
                    img {
                        height: 200px;
                        object-fit: cover;
                        @include m.tablet {
                            height: 260px;
                        }
                    }
                }
            }
            .post-quote {
                height: 260px;
            }
            .post-content {
                padding: 2rem;
            }
            .main-post {
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                z-index: 100;
                background: rgba(var(--light-head-color-rgb), .5);
                padding: 2.5rem;
                &:hover {
                    background: rgba(var(--light-head-color-rgb), .75);
                }
                p,
                h4 {
                    color: var(--light-back-main-color);
                }
                .info-post {
                    font-size: 1.125em;
                    margin-bottom: 1rem !important;
                }
                h4 {
                    font-size: 2.62em;
                    line-height: 1.2em;
                    @include m.tablet {
                        margin-bottom: 2rem !important;
                    }
                }
                .info-description {
                    font-size: 1.25em;
                    line-height: 1.5em;
                    display: none;
                    @include m.tablet {
                        display: block;
                    }
                }
                @include m.tablet {
                    padding: 4.5rem;
                    &.main-post-wide {
                        padding: 9rem;
                        justify-content: center !important;
                        text-align: center;
                    }
                }
            }
        }
    }
    &.light-scheme {
        .blog-content {
            .post-content {
                background: var(--light-back-main-color);
                .info-post {
                    color: var(--light-accent-color);
                }
            }
            .post-quote {
                color: var(--light-back-main-color);
                background: url('../../../../assets/fronted/img/quote.png') var(--light-accent-color) top left no-repeat;
                h3 {
                    font-size: 1.4em;
                    color: var(--light-back-main-color);
                }
            }
        }
    }
    &.blog.dark-scheme {
        .blog-content {
            .post-content {
                background: var(--dark-back-main-color);
                .info-post {
                    color: var(--dark-accent-color);
                }
            }
            .post-quote {
                color: var(--dark-back-main-color);
                background: url('../../../../assets/fronted/img/quote_dark.png') var(--dark-accent-color) top left no-repeat;
                h3 {
                    font-size: 1.4em;
                    color: var(--dark-back-main-color);
                }
            }
        }
    }
}

.light-scheme {
    .post-container {
        .post-quote {
            color: var(--light-back-main-color);
            background: url('../../../../assets/fronted/img/quote.png') var(--light-accent-color) top left no-repeat;
            h3 {
                font-size: 1.4em;
                color: var(--light-back-main-color);
            }
        }
        .post-text {
            .blockquote,
            blockquote {
                border-left: 4px solid var(--light-accent-color);
            }
        }
    }
}

.dark-scheme {
    .post-container {
        .post-quote {
            color: var(--dark-back-main-color);
            background: url('../../../../assets/fronted/img/quote_dark.png') var(--dark-accent-color) top left no-repeat;
            h3 {
                font-size: 1.4em;
                color: var(--dark-back-main-color);
            }
        }
        .post-text {
            .blockquote,
            blockquote {
                border-left: 4px solid var(--dark-accent-color);
            }
        }
    }
}